$btnHeight: 52px;
$btnMargin: 48px;

.container {
    position: fixed;
    bottom: $btnMargin;
    right: $btnMargin;
    z-index: 2;

    display: flex;
    gap: 12px;
}
